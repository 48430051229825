
<template>
    <div>
        <div class="box">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="box_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="box_top_img_min"></el-image>
            <div class="box_title">仲裁费计算</div>
            <el-form :model="dataForm" class="box_form">
                <el-form-item label="争议金额:" prop="money"  label-width="100px">
                    <el-input v-model="dataForm.money" type="number" placeholder="争议金额" @input="moneyInput()"></el-input>
                </el-form-item>
                <el-form-item label="案件受理费:" prop="money1"  label-width="100px">
                    <el-input v-model="dataForm.money1" type="number" placeholder="案件受理费" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="案件处理费:" prop="money2"  label-width="100px">
                    <el-input v-model="dataForm.money2" type="number" placeholder="案件处理费" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="总计:" prop="allMoney"  label-width="100px">
                    <el-input v-model="dataForm.allMoney" placeholder="总计" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <div style="width: 400%;margin-left:30%;color: #25adfb">仅供参考，计算结果以立案室的计算为准</div>
            <div class="calculation-rules">
                <h3>本会仲裁费收费标准：</h3>
                <p>当事人申请仲裁，应当向本会预交仲裁费。仲裁费包括案件受理费和案件处理费。</p>
                <p>根据吉省价收（2017）133号文，本会案件受理费收取标准如下：</p>
                <ul>
                    <li>争议金额（人民币） 案件受理费（人民币）</li>
                    <li>1000元以下：0.068</li>
                    <li>1000元以下：0.068</li>
                    <li>1001元至50000元：0.0385</li>
                    <li>50001元至100000元：0.03</li>
                    <li>100001元至200000元：0.0214</li>
                    <li>200001元至500000元：0.0128</li>
                    <li>500001元至1000000元：0.0064</li>
                    <li>1000001元以上的部分 按0.3%交纳</li>
                </ul>
                <p>案件处理费按以下标准计算如下：</p>
                <ul>
                    <li>争议金额为200万元的，常规处理费按3000元收取。</li>
                    <li>争议金额在200万元以上的，标的额每增加100万元，常规处理费加收200元，标的额超过5亿元的，不再加收。</li>
                    <li>争议标的额在200万元以下的，标的额每减少50万元，常规处理费减收500元。争议标的额低于30万元的，常规处理费按500元收取。</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                dataForm: {
                    money: '',
                    money1: '',
                    money2: '',
                    allMoney: '',
                },
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {

        },

        methods: {
            moneyInput() {
                if (this.dataForm.money == 0 && this.dataForm.money == "") {
                    this.dataForm = {
                        money: this.dataForm.money,
                        money1: '',
                        money2: '',
                        allMoney: '',
                    }
                }
                else {
                    this.calculateAcceptanceFee(this.dataForm.money)
                    this.calculateProcessingFee(this.dataForm.money)
                    this.calculateTotalFee()
                }
            },

            // 案件受理费计算
            calculateAcceptanceFee(amount) {
                if (amount <= 1000) {
                    this.dataForm.money1 =  68;
                } else if (amount <= 50000) {
                    this.dataForm.money1 = 68 + (amount - 1000) * 0.0385;
                } else if (amount <= 100000) {
                    this.dataForm.money1 = 68 + (50000 - 1000) * 0.0385 + (amount - 50000) * 0.03;
                } else if (amount <= 200000) {
                    this.dataForm.money1 = 68 + (50000 - 1000) * 0.0385 + (100000 - 50000) * 0.03 + (amount - 100000) * 0.0214;
                } else if (amount <= 500000) {
                    this.dataForm.money1 = 68 + (50000 - 1000) * 0.0385 + (100000 - 50000) * 0.03 + (200000 - 100000) * 0.0214 + (amount - 200000) * 0.0128;
                } else if (amount <= 1000000) {
                    this.dataForm.money1 = 68 + (50000 - 1000) * 0.0385 + (100000 - 50000) * 0.03 + (200000 - 100000) * 0.0214 + (500000 - 200000) * 0.0128 + (amount - 500000) * 0.0064;
                } else {
                    this.dataForm.money1 = 68 + (50000 - 1000) * 0.0385 + (100000 - 50000) * 0.03 + (200000 - 100000) * 0.0214 + (500000 - 200000) * 0.0128 + (1000000 - 500000) * 0.0064 + (amount - 1000000) * 0.003;
                }
                this.dataForm.money1 = Math.round(this.dataForm.money1)
            },

            // 案件处理费计算
            calculateProcessingFee(amount) {
                var money = 0
                if (amount <= 2000000) {
                    if (amount <= 300000) {
                        money = 500
                    }
                    else if (300000 < amount && amount <= 500000) {
                        money = 1000
                    }
                    else if (500000 < amount && amount <= 1000000) {
                        money = 1500
                    }
                    else if (1000000 < amount && amount <= 1500000) {
                        money = 2000
                    }
                    else if (1500000 < amount && amount < 2000000) {
                        money = 2500
                    }
                    else {
                        money = 3000
                    }
                }
                else if (3000000 <= amount && amount <= 5000000000) {
                    money = 3000 + Math.floor((amount - 2000000)/1000000) * 200
                }
                else {
                    money = 103000
                }
                this.dataForm.money2 = Math.round(money)
            },

            // 计算合计费用
            calculateTotalFee() {
                this.dataForm.allMoney = this.dataForm.money1 + this.dataForm.money2
            }
        }
    }
</script>


<style scoped>
    .box {
        position: relative;
    }

    .box_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .box_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .box_title {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .box_form {
        margin-top: 50px;
        width: 40%;
        margin-left: 30%;
        margin-bottom: 10px;
    }

    .calculation-rules {
        margin-top: 50px;
        width: 40%;
        margin-left: 30%;
        margin-bottom: 50px;
    }
</style>
